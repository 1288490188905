import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <br />
    <h4>{`1. Go to trailguide.net using your web browser on the computer`}</h4>
    <h4>{`2. Log in (using your Google or Facebook account)`}</h4>
    <img className="mb-8" src={img("/howto/trailguide-add-trail-login.jpg")} />
    <h4>{`3. Click on add to open the trail editor`}</h4>
    <img className="mb-8" src={img("/howto/trailguide-add-trail.jpg")} />
    <h4>{`4. Drag and drop a GPS-file into the trail editor (.gpx or .fit)`}</h4>
    <p>
  Crop the file if needed by sliding the start and end point.
  <br />
  You can also reverse the trail if it happens to be in the wrong direction.
  <br />
  <img className="my-8" src={img("/howto/trailguide-add-trail-drop.jpg")} />
    </p>
    <h4>{`5. Select activity type, difficulty level and visibility.`}</h4>
    <img src={img("/howto/trailguide-add-trail-category.jpg")} />
    <p>
  Add a trail name, and description and use the bottom box to describe where to
  find the trail. The description box is aimed to give users an idea of how the
  trail is generally experienced.
    </p>
    <br />
    <strong>Difficulty level</strong>: Imagine you are on the trail with bicyle trailer
with a sleeping kid in.
    <ul>
      <li parentName="ul">{`Easy/Green: The kid wakes up, but is not crying.`}</li>
      <li parentName="ul">{`Intermediate/Blue: The kid wakes up and is crying.`}</li>
      <li parentName="ul">{`Difficult/Red: The kid is gone.`}</li>
      <li parentName="ul">{`Expert/Black: The bicycle trailer is gone.`}</li>
    </ul>
    <br />A <strong>Public</strong> trail will be visible on the map. A <strong>
  hidden
    </strong> trail will only be visible in the user profile page. A <strong>
  secret
    </strong> trail will not be visible to anyone not having the direct link.
    <img className="my-8" src={img("/howto/trailguide-add-trail-description.jpg")} />
    <p>
  Select characteristics that describe the trail. Add a YouTube link if you want
  to embed a video.
  <br />
  <br />
  <strong>Click save and you’re done!</strong>
    </p>
    <img className="my-8" src={img("/howto/trailguide-add-trail-characteristics.jpg")} />
    <h4>{`6. Add images`}</h4>
    <p>
  After you’ve saved the trail you can also add photos. A great picture will
  really make the trail stand out and encourage people to check it out.
  <img className="my-8" src={img("/howto/trailguide-add-trail-images.jpg")} />
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      